import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { useAppSelector } from 'src/store/store';
import { selectShouldMinimizeProjectPanel } from 'src/store/ui/projectPanel';
import { ModelsPanel } from 'src/visualization/ProjectPanel/components/ModelsPanel';
import { ObjectsPanel } from 'src/visualization/ProjectPanel/components/ObjectsPanel';
import { ProjectSelector } from 'src/visualization/ProjectPanel/components/ProjectSelector';

interface ProjectPanelProps {
    sx?: SxProps<Theme>;
}

export function ProjectPanel({ sx }: ProjectPanelProps) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper sx={{ width: 275 }} elevation={4}>
                    <ProjectSelector />
                    {!shouldMinimizeProjectPanel && (
                        <>
                            <Divider />
                            <ModelsPanel />
                            <ObjectsPanel />
                        </>
                    )}
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}
