import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { PersistentTextField } from '@local/web-design-system/dist/components/PersistentTextField';
import ElementsIcon from '@local/web-design-system/dist/icons/Menu/ElementsIcon';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DESIGN_GEOMETRY_SCHEMA, PARAMETERIZED_GEOMETRY_SUFFIX } from 'src/constants';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { selectWorkspaceName } from 'src/store/evo/selectors';
import { setParameterizedGeometry } from 'src/store/project/projectSlice';
import {
    selectCurrentAggregateGeometry,
    selectCurrentProjectName,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { decorateNewObject } from 'src/utils/decorateObject';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import {
    PARAM_BUTTON_LABEL,
    PARAM_OBJECT_NAME,
    PARAM_OBJECT_TEXT_INPUT_LABEL,
    PARAM_TITLE,
    WORKSPACE_TEXT_INPUT_LABEL,
    CANCEL,
    PARAMETERIZING_MODEL,
} from './AggregateDialog.constants';
import { useStyles } from './AggregateDialog.styles';

export const ParameterizeModelDialog = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { classes } = useStyles();
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const aggregateGeometryObject = useAppSelector(selectCurrentAggregateGeometry);
    const projectName = useAppSelector(selectCurrentProjectName);
    const workspaceName = useAppSelector(selectWorkspaceName);
    const dispatch = useAppDispatch();

    async function action(accept: boolean | null) {
        if (accept) {
            await executeTransformation(
                GtmMeshTransformationAction.ParameterizeVolumes,
                [aggregateGeometryObject!],
                { name: projectName + PARAMETERIZED_GEOMETRY_SUFFIX },
                {
                    createdObjectsHandler: (createdObjects) => {
                        createdObjects.forEach((obj, index) => {
                            dispatch(
                                setParameterizedGeometry(
                                    decorateNewObject(
                                        obj,
                                        `Parameterized geometry ${index + 1}`,
                                        DESIGN_GEOMETRY_SCHEMA,
                                    ),
                                ),
                            );
                        });
                    },
                },
            );
        }
        onClose();
    }

    return (
        <Dialog
            dialogTitle={PARAM_BUTTON_LABEL}
            confirmText={PARAM_TITLE}
            cancelText={CANCEL}
            open={open}
            icon={<ElementsIcon />}
            action={(response) => {
                action(response);
            }}
        >
            <Grid
                container
                item
                xs
                direction="row"
                flexWrap="nowrap"
                width="100%"
                className={classes.paramDialogRoot}
            >
                <Box className={classes.boxWidth}>
                    <Typography
                        id="name-input-label"
                        variant="body2"
                        className={classes.paramButtonText}
                    >
                        {PARAM_OBJECT_TEXT_INPUT_LABEL}
                    </Typography>
                </Box>

                <PersistentTextField defaultValue={PARAM_OBJECT_NAME} disabled />
            </Grid>
            <Grid
                container
                item
                xs
                direction="row"
                flexWrap="nowrap"
                width="100%"
                className={classes.paramDialogRoot}
            >
                <Box className={classes.boxWidth}>
                    <Typography
                        id="workspace-input-label"
                        variant="body2"
                        className={classes.paramButtonText}
                    >
                        {WORKSPACE_TEXT_INPUT_LABEL}
                    </Typography>
                </Box>

                <PersistentTextField defaultValue={workspaceName} disabled />
            </Grid>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={PARAMETERIZING_MODEL}
            />
        </Dialog>
    );
};
