import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME, MESH_SCHEMA } from 'src/constants';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { setAggregate } from 'src/store/project/projectSlice';
import { selectCurrentBounds } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { decorateNewObject } from 'src/utils/decorateObject';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import { useStyles } from './ObjectListItemControl.styles';
import { START_RESET_AGGREGATE_GEOM_MESSAGE } from './ResetAggregateControl.constants';

export function ResetAggregateControl() {
    const { classes } = useStyles();
    const currentBounds = useAppSelector(selectCurrentBounds);
    const dispatch = useAppDispatch();
    const { resetVolumes } = useVolumesManager();
    const { disaggregateAllObjects } = useObjectManager();

    const { executeTransformation, transformationStatus } = useTransformationManager();

    async function performReset() {
        await executeTransformation(
            GtmMeshTransformationAction.InitAggregateGeom,
            [],
            {
                ...currentBounds!,
            },
            {
                createdObjectsHandler: (createdObjects) =>
                    dispatch(
                        setAggregate(
                            decorateNewObject(
                                createdObjects[0],
                                AGGREGATE_GEOMETRY_NAME,
                                MESH_SCHEMA,
                            ),
                        ),
                    ),
                // Resetting the aggregate invalidates the
                // volumes that were computed from the previous aggregate.
                handleAdditionalSideEffects: (_) => {
                    resetVolumes();
                    disaggregateAllObjects();
                },
            },
        );
    }

    return (
        <>
            <Tooltip title="Reset Aggregate" placement="top" arrow enterDelay={0}>
                <IconButton onClick={performReset} className={classnames(classes.removeIconButton)}>
                    <BrokenImageIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={START_RESET_AGGREGATE_GEOM_MESSAGE}
            />
        </>
    );
}
