import ViewInArIcon from '@mui/icons-material/ViewInAr';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DEFAULT_TOLERANCE } from 'src/constants';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import { START_AGGREGATE_GEOM_MESSAGE } from './AggregateControl.constants';
import { useStyles } from './ObjectListItemControl.styles';

export function AggregateControl({
    inputMesh,
    handleRemoveObject,
}: {
    inputMesh: GtmEvoOutputObject;
    handleRemoveObject: () => void;
}) {
    const { classes } = useStyles();
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const { resetVolumes } = useVolumesManager();
    const { findObjectAndSetAggregated } = useObjectManager();

    async function performAggregation() {
        await executeTransformation(
            GtmMeshTransformationAction.AddToAggregateGeom,
            [inputMesh],
            {
                aggregateGeomId: currentAggregateGeometry!,
                tolerance: DEFAULT_TOLERANCE,
                noSelfIntersectionsInParts: true,
            },
            {
                handleAdditionalSideEffects: (_) => {
                    // Adding a surface to the aggregate invalidates the
                    // volumes that were computed from the previous aggregate.
                    resetVolumes();

                    // Once a surface is aggregated, we want to mark it as such and hide it.
                    findObjectAndSetAggregated(inputMesh.id, true);
                    handleRemoveObject();
                },
            },
        );
    }

    return (
        <>
            <Tooltip title="Add to Aggregate" placement="top" arrow enterDelay={0}>
                <IconButton
                    onClick={performAggregation}
                    className={classnames(classes.removeIconButton)}
                >
                    <ViewInArIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={START_AGGREGATE_GEOM_MESSAGE}
            />
        </>
    );
}
