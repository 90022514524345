import { useBaseXyz } from '@local/webviz/dist/context';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import ShowIcon from '@mui/icons-material/RemoveRedEye';
import HideIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MouseEvent, useState } from 'react';

import { BoxSearchIcon } from 'src/assets/BoxSearchIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { GtmModel, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deselectCurrentModelSelectedObject,
    removeInputObjectFromModelInCurrentProject,
    setCurrentModelSelectedObjectIndex,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModelObjects,
    selectCurrentModelSelectedObjectIndex,
    selectCurrentProjectModels,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { fileNameExtensionRemover } from 'src/utils';
import {
    CREATE_ANALYTICAL_BOUNDARY_LABEL,
    EMPTY_OBJECTS_MESSAGE,
    NO_OBJECTS_YET_MESSAGE,
    UPLOAD_LABEL,
    WORKSPACE_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

import { BoundingBoxDialog } from './BoundaryCreationDialog/BoundaryCreationDialog';

export function ModelObjectsTab() {
    const theme = useTheme();
    const allModels = useAppSelector(selectCurrentProjectModels);
    const currentModelObjects = useAppSelector(selectCurrentModelObjects);
    const [showSetBoundaryDialog, setShowSetBoundaryDialog] = useState(false);

    const gatherExistingProjectNames = (models: GtmModel[]) =>
        models
            .filter((model: GtmModel) => model.name !== undefined)
            .map((model) => model.name?.toLowerCase() ?? '');

    if (currentModelObjects.length === 0) {
        return <ModelObjectsEmptyState />;
    }
    return (
        <Box p={2}>
            <Box
                sx={{
                    height: '200px',
                    overflowY: 'scroll',
                    borderRadius: 0.5,
                    border: 1,
                    borderColor: 'divider',
                }}
            >
                <List dense disablePadding>
                    {currentModelObjects.map((object, index) => (
                        <ModelObjectsListItem key={object.id} index={index} object={object} />
                    ))}
                </List>
            </Box>
            {!showSetBoundaryDialog && (
                <Button
                    sx={{ marginTop: theme.spacing(2), width: '100%' }}
                    size="small"
                    variant="outlined"
                    onClick={() => setShowSetBoundaryDialog(true)}
                >
                    {CREATE_ANALYTICAL_BOUNDARY_LABEL}
                </Button>
            )}
            {showSetBoundaryDialog && (
                <BoundingBoxDialog
                    existingProjectNames={gatherExistingProjectNames(allModels)}
                    onClose={() => setShowSetBoundaryDialog(false)}
                    onBoundaryCreated={() => {}}
                />
            )}
        </Box>
    );
}

function ModelObjectsListItem({ object, index }: { object: GtmProjectInput; index: number }) {
    const dispatch = useAppDispatch();
    const { loadGtmObject, isObjectOnPlotByObjectId, removeGtmObject } =
        useSceneObjectDataManager();
    const isObjectOnPlot = isObjectOnPlotByObjectId(object.id);
    const currentModelSelectedObjectIndex = useAppSelector(selectCurrentModelSelectedObjectIndex);
    const { getZoomToViewTool } = useBaseXyz();
    const { syncProject } = useProjectSynchronizer();
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);

    const handleShowItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        loadGtmObject(object.id, object.version, object.name);
    };

    const handleHideItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        removeGtmObject(object.id);
    };

    const handleRemoveItemOnClick = async (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(removeInputObjectFromModelInCurrentProject([selectedModelIndex, object]));
        syncProject();
        removeGtmObject(object.id);
    };

    const handleOnClick = () => {
        if (currentModelSelectedObjectIndex === index) {
            dispatch(deselectCurrentModelSelectedObject());
        } else {
            dispatch(setCurrentModelSelectedObjectIndex(index));
            getZoomToViewTool().zoomToView(object.id);
        }
    };

    return (
        <ListItem disableGutters disablePadding divider>
            <ListItemButton
                selected={currentModelSelectedObjectIndex === index}
                sx={(theme) => ({ padding: theme.spacing(0, 1) })}
                onClick={handleOnClick}
            >
                <ListItemIcon sx={{ minWidth: '36px' }}>
                    {isObjectOnPlot ? (
                        <IconButton
                            disableRipple
                            edge="end"
                            onClick={handleHideItemOnClick}
                            size="small"
                        >
                            <ShowIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            disableRipple
                            edge="end"
                            onClick={handleShowItemOnClick}
                            size="small"
                        >
                            <HideIcon />
                        </IconButton>
                    )}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <OverflowTooltipTypography
                            variant="caption"
                            sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {fileNameExtensionRemover(object.name)}
                        </OverflowTooltipTypography>
                    }
                />
                <IconButton disableRipple edge="end" onClick={handleRemoveItemOnClick} size="small">
                    <RemoveIcon />
                </IconButton>
            </ListItemButton>
        </ListItem>
    );
}

function ModelObjectsEmptyState() {
    const theme = useTheme();

    return (
        <Stack
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                padding: theme.spacing(3),
            }}
        >
            <BoxSearchIcon fontSize="large" />
            <Typography sx={{ margin: theme.spacing(0.5, 0, 1, 0) }} color="secondary" variant="h5">
                {NO_OBJECTS_YET_MESSAGE}
            </Typography>
            <Typography color="secondary" variant="caption">
                {EMPTY_OBJECTS_MESSAGE}
            </Typography>
            <Stack
                spacing={2}
                sx={{ marginTop: theme.spacing(2), textTransform: 'capitalize' }}
                direction="row"
            >
                <Button variant="outlined" size="small" startIcon={<FolderIcon />}>
                    {WORKSPACE_LABEL}
                </Button>
                <Button variant="contained" size="small" startIcon={<UploadIcon />}>
                    {UPLOAD_LABEL}
                </Button>
            </Stack>
        </Stack>
    );
}
