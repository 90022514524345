import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { UpdateSnapshot, Vector3, Snapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, LinesMode } from '@local/webviz/dist/xyz';

export interface BoundingBox {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    zMin: number;
    zMax: number;
}

export function computeBoundingBoxFromCenter(
    position: Vector3 | Float32Array,
    offset: number,
): BoundingBox {
    return {
        xMin: position[0] - offset,
        xMax: position[0] + offset,
        yMin: position[1] - offset,
        yMax: position[1] + offset,
        zMin: position[2] - offset,
        zMax: position[2] + offset,
    };
}

export function computeBoundingBoxVertices(box: BoundingBox): Float32Array {
    const { xMin, xMax, yMin, yMax, zMin, zMax } = box;
    // prettier-ignore
    return Float32Array.from([
        xMin, yMin, zMin,
        xMax, yMin, zMin,
        xMax, yMax, zMin,
        xMin, yMax, zMin,
        xMin, yMin, zMax,
        xMax, yMin, zMax,
        xMax, yMax, zMax,
        xMin, yMax, zMax
    ]);
}

export function getBoundingBoxSnapshot(box: BoundingBox, label: string): UpdateSnapshot {
    const elementId = `bounding-box-${label}`;
    const viewId = label;

    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Lines,
            vertices: computeBoundingBoxVertices(box),
            segments: [0, 1, 1, 2, 2, 3, 3, 0, 0, 4, 4, 5, 5, 6, 6, 7, 7, 4, 1, 5, 2, 6, 3, 7],
        },

        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: [100, 255, 0],
            mode: LinesMode.Lines,
            radius: 4,
        }),
    };
}

export function getPlotBoundingBox(xyzSnapshot: Snapshot): BoundingBox | undefined {
    const { boundingBox } = xyzSnapshot.plot as any; // boundingBox exists on `InternalPlotState`, which is not an exported type.
    if (boundingBox) {
        return {
            xMin: boundingBox.min[0],
            xMax: boundingBox.max[0],
            yMin: boundingBox.min[1],
            yMax: boundingBox.max[1],
            zMin: boundingBox.min[2],
            zMax: boundingBox.max[2],
        };
    }
    return undefined;
}
