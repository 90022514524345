export interface ProjectPanelState {
    objectsPanelTabSelection: ObjectsTabSelection;
    isSearchingWorkspace: boolean;
    workspaceSearchTerm: string;
    selectedWorkspaceObjectIds: string[];
    shouldMinimizeProjectPanel: boolean;
}

export const enum ObjectsTabSelection {
    ModelObjects,
    WorkSpaceObjects,
}
