import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from 'react';

import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { GtmModel } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deleteModelInCurrentProject,
    deselectCurrentModelSelectedObject,
    setSelectedModelIndex,
    updateModelAtIndexForCurrentProject,
} from 'src/store/project/projectSlice';
import {
    selectCurrentProjectModelNames,
    selectCurrentProjectModels,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    clearSelectedWorkspaceObjectIds,
    ObjectsTabSelection,
    setObjectsPanelTabSelection,
} from 'src/store/ui/projectPanel';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import {
    getDeleteModelMessage,
    INVALID_NEW_MODEL_NAME_MESSAGE,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ModelListEntry({
    index,
    model,
    setShouldDisableAddModelButton,
    disableMenu,
}: {
    index: number;
    model: GtmModel;
    setShouldDisableAddModelButton: Dispatch<SetStateAction<boolean>>;
    disableMenu?: boolean;
}) {
    const applyTrace = useTrace(`models-list-entry-${index}`);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { clearGtmObjects } = useSceneObjectDataManager();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState('');
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const currentProjectModelNames = useAppSelector(selectCurrentProjectModelNames);
    const isNewNameInvalid = useMemo(() => {
        let projectNames = currentProjectModelNames;
        if (isRenaming) {
            projectNames = projectNames.filter((name) => name !== model.name);
        }
        return projectNames.includes(newName);
    }, [currentProjectModelNames, newName, isRenaming]);
    const [modelToDelete, setModelToDelete] = useState<GtmModel | null>(null);
    const { loadGtmObject } = useSceneObjectDataManager();
    const { syncProject } = useProjectSynchronizer();
    const models = useAppSelector(selectCurrentProjectModels);

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleRenameOnClick = () => {
        setShouldDisableAddModelButton(true);
        setIsRenaming(true);
        setNewName(model.name ?? '');
        handleCloseMenu();
    };

    const handleRenameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleConfirmRename = () => {
        dispatch(updateModelAtIndexForCurrentProject([{ name: newName.trim() }, index]));
        syncProject();

        setNewName('');
        setIsRenaming(false);
        setShouldDisableAddModelButton(false);
    };

    const handleCancelRename = () => {
        setNewName('');
        setIsRenaming(false);
        setShouldDisableAddModelButton(false);
    };

    const handleDeleteOnClick = () => {
        setModelToDelete(model);
    };

    const createOnClickHandler = (entryIndex: number) => () => {
        if (entryIndex === selectedModelIndex) {
            return;
        }

        clearGtmObjects();
        dispatch(setSelectedModelIndex(entryIndex));
        dispatch(deselectCurrentModelSelectedObject());
        dispatch(setObjectsPanelTabSelection(ObjectsTabSelection.ModelObjects));
        dispatch(clearSelectedWorkspaceObjectIds());

        model.inputObjects?.forEach((object) => {
            loadGtmObject(object.id, object.version, object.name);
        });
    };

    const handleDeleteConfirm = () => {
        if (modelToDelete) {
            dispatch(deleteModelInCurrentProject(modelToDelete));
            syncProject();
            if (index === selectedModelIndex) {
                dispatch(setSelectedModelIndex(0));
            }
        }

        setModelToDelete(null);
    };

    const handleDeleteCancel = () => {
        setModelToDelete(null);
    };

    return (
        <>
            <ListItem
                disableGutters
                divider
                secondaryAction={
                    !isRenaming && (
                        <IconButton
                            automation-id={applyTrace('menu-button')}
                            disabled={disableMenu}
                            size="small"
                            disableRipple
                            onClick={handleOpenMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                    )
                }
                disablePadding
            >
                {isRenaming ? (
                    <Stack
                        sx={{
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                        direction="row"
                        p={theme.spacing(0.5, 1, 1, 2)}
                    >
                        <TextField
                            automation-id={applyTrace('rename-text-field')}
                            size="small"
                            variant="standard"
                            value={newName}
                            onChange={handleRenameOnChange}
                            InputProps={{ sx: { fontSize: '12px' } }}
                            error={isNewNameInvalid}
                            helperText={isNewNameInvalid ? INVALID_NEW_MODEL_NAME_MESSAGE : null}
                        />
                        <Stack direction="row">
                            <IconButton
                                automation-id={applyTrace('rename-confirm-button')}
                                sx={{ paddingRight: theme.spacing(1) }}
                                size="small"
                                disabled={isNewNameInvalid || newName.trim() === ''}
                                disableRipple
                                onClick={handleConfirmRename}
                            >
                                <CheckIcon color={isNewNameInvalid ? 'disabled' : 'primary'} />
                            </IconButton>
                            <IconButton
                                automation-id={applyTrace('rename-cancel-button')}
                                sx={{ padding: 0 }}
                                size="small"
                                disableRipple
                                onClick={handleCancelRename}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : (
                    <ListItemButton
                        selected={index === selectedModelIndex}
                        onClick={createOnClickHandler(index)}
                    >
                        <ListItemText
                            disableTypography
                            primary={
                                <OverflowTooltipTypography
                                    variant="caption"
                                    sx={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {model.name}
                                </OverflowTooltipTypography>
                            }
                        />
                    </ListItemButton>
                )}
            </ListItem>
            <PanelItemMenu
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                onDelete={models.length > 1 ? handleDeleteOnClick : undefined}
                onRename={handleRenameOnClick}
            />

            <DeleteDialog
                shouldOpen={Boolean(modelToDelete)}
                message={getDeleteModelMessage(modelToDelete?.name ?? '')}
                handleCancel={handleDeleteCancel}
                handleDelete={handleDeleteConfirm}
            />
        </>
    );
}
