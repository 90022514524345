import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { CloseRetryActions } from 'src/components/ActionDialog/CloseRetryActions';
import { PercentProgress } from 'src/components/PercentProgress/PercentProgress';

import {
    START_ANALYTICAL_MODEL_CREATION_MESSAGE,
    ANALYTICAL_MODEL_CREATION_SUCCESS_MESSAGE,
    ANALYTICAL_MODEL_CREATION_ERROR_MESSAGE,
    ANALYTICAL_MODEL_CREATION_ERROR_TITLE,
} from './BoundaryCreationDialog.constants';

export enum BoundingBoxModalState {
    STARTED,
    ANALYTICAL_BOUNDARY_CREATED,
    AGGREGATE_GEOMETRY_INITIALIZED,
    PROJECT_UPLOADED,
    SUCCESS,
    ERROR,
}

enum InternalState {
    IN_PROGRESS,
    SUCCESS,
    ERROR,
}

// All of these statuses are just gueses. Clipping/Boundary creation is fast but the
// most work. Aggregate initialization is very fast. Project upload can be slow as we
// rely on the file service.
const stateToProgress = {
    [BoundingBoxModalState.STARTED]: 0,
    [BoundingBoxModalState.ANALYTICAL_BOUNDARY_CREATED]: 45,
    [BoundingBoxModalState.AGGREGATE_GEOMETRY_INITIALIZED]: 60,
    [BoundingBoxModalState.PROJECT_UPLOADED]: 100,
    [BoundingBoxModalState.SUCCESS]: 100,
    [BoundingBoxModalState.ERROR]: 100,
};

const stateToInternalState = {
    [BoundingBoxModalState.STARTED]: InternalState.IN_PROGRESS,
    [BoundingBoxModalState.ANALYTICAL_BOUNDARY_CREATED]: InternalState.IN_PROGRESS,
    [BoundingBoxModalState.AGGREGATE_GEOMETRY_INITIALIZED]: InternalState.IN_PROGRESS,
    [BoundingBoxModalState.PROJECT_UPLOADED]: InternalState.IN_PROGRESS,
    [BoundingBoxModalState.SUCCESS]: InternalState.SUCCESS,
    [BoundingBoxModalState.ERROR]: InternalState.ERROR,
};

const internalStateToTitle = {
    [InternalState.IN_PROGRESS]: START_ANALYTICAL_MODEL_CREATION_MESSAGE,
    [InternalState.SUCCESS]: ANALYTICAL_MODEL_CREATION_SUCCESS_MESSAGE,
    [InternalState.ERROR]: ANALYTICAL_MODEL_CREATION_ERROR_TITLE,
};

const internalStateToMessage = {
    [InternalState.IN_PROGRESS]: undefined,
    [InternalState.SUCCESS]: undefined,
    [InternalState.ERROR]: ANALYTICAL_MODEL_CREATION_ERROR_MESSAGE,
};

const internalStateToIcon = {
    [InternalState.IN_PROGRESS]: <BuildCircleOutlinedIcon color="primary" fontSize="large" />,
    [InternalState.SUCCESS]: <CheckCircle color="success" fontSize="large" />,
    [InternalState.ERROR]: <WarningIcon color="error" fontSize="large" />,
};

export interface BoundingBoxModalProps {
    isOpen: boolean;
    state: BoundingBoxModalState;
    onRetry: () => void;
    onClose: () => void;
}

export function BoundingBoxModal({
    isOpen,
    state,
    onRetry,
    onClose,
}: Readonly<BoundingBoxModalProps>) {
    const internalState = stateToInternalState[state];
    const modalTitle = internalStateToTitle[internalState];
    const modalMessage = internalStateToMessage[internalState];
    const modalIcon = internalStateToIcon[internalState];

    const progressBar =
        internalState !== InternalState.ERROR ? (
            <PercentProgress percent={stateToProgress[state]} showPercentText />
        ) : undefined;

    return (
        <ActionDialog
            open={isOpen}
            title={modalTitle}
            message={modalMessage}
            icon={modalIcon}
            actions={
                internalState === InternalState.ERROR && (
                    <CloseRetryActions onClose={onClose} onRetry={onRetry} />
                )
            }
            progressBar={progressBar}
        />
    );
}
