import { TransformationStatus } from 'src/hooks/transformation/useTransformationManager';
import { ProgressModal } from 'src/visualization/ProgressModal/ProgressModal';
import {
    PERFORMING_TRANSFORMATION,
    SYNCING_MESSAGE,
    TRANSFORATION_SUCCESS_MESSAGE,
    TRANSFORATION_FAILURE_MESSAGE,
    EXECUTING_TRANSFORMATIONS,
} from 'src/visualization/TransformationProgressModal/TransformationProgressModal.constants';

function statusToString(status: TransformationStatus | undefined, transformationMessage?: string) {
    switch (status) {
        case TransformationStatus.Transforming:
            return transformationMessage ?? PERFORMING_TRANSFORMATION;
        case TransformationStatus.Uploading:
            return SYNCING_MESSAGE;
        case TransformationStatus.Complete:
            return TRANSFORATION_SUCCESS_MESSAGE;
        case TransformationStatus.Failed:
            return TRANSFORATION_FAILURE_MESSAGE;
        default:
            return '';
    }
}

export function TransformationProgressModal({
    transformationStatus,
    transformationMessage,
}: {
    transformationStatus: TransformationStatus | undefined;
    transformationMessage?: string;
}) {
    return (
        <ProgressModal
            open={transformationStatus !== undefined}
            title={EXECUTING_TRANSFORMATIONS}
            dialogContent={statusToString(transformationStatus, transformationMessage)}
        />
    );
}
