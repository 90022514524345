import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useListFilesQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { SidebarLeft } from 'src/assets/SidebarLeft';
import { SidebarRight } from 'src/assets/SidebarRight';
import { GtmProject } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectManager } from 'src/hooks/project';
import { selectWorkspaceName } from 'src/store/evo/selectors';
import { setSelectedModelIndex } from 'src/store/project/projectSlice';
import { selectCurrentProjectData } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    selectShouldMinimizeProjectPanel,
    toggleProjectPanelMinimize,
} from 'src/store/ui/projectPanel';
import { PROJECT_EXTENSION } from 'src/strings';
import { fileNameExtensionRemover } from 'src/utils';
import { getNextSequentialName } from 'src/utils/stringHelpers';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import {
    AT_LEAST_ONE_PROJECT_REQUIRED_ERROR,
    getDeleteProjectMessage,
    getProjectCreationErrorMessage,
    INVALID_NEW_PROJECT_NAME_MESSAGE,
    NEW_PROJECT_NAME,
    PROJECT_INITIALIZING_ERROR,
    PROJECT_RENAME_ERROR,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ProjectSelector() {
    const dispatch = useAppDispatch();
    const applyTrace = useTrace('project-selector');
    const { orgUuid, workspaceUuid, hubCode, projectName: urlProjectName } = useParams();
    const { initializeNewProject, deleteProject, renameProject, loadProjectFromFile } =
        useProjectManager();
    const { data: projectFilesData } = useListFilesQuery({
        organisationId: orgUuid ?? '',
        workspaceId: workspaceUuid ?? '',
        fileName: `.${PROJECT_EXTENSION}`,
    });
    const { addMessage } = useMessagesContext();
    const navigate = useNavigate();
    const workspaceName = useAppSelector(selectWorkspaceName);
    const selectedProject = useAppSelector(selectCurrentProjectData);
    const [newProjectName, setNewProjectName] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [projectNames, setProjectNames] = useState<string[]>([]);
    const [projectToDelete, setProjectToDelete] = useState<GtmProject | null>(null);
    const [isSavingProject, setIsSavingProject] = useState(false);
    const previousWorkspaceUuidAndProjectName = useRef<string | null>(null);
    const { loadGtmObject } = useSceneObjectDataManager();
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);

    const isNewNameInvalid = useMemo(() => {
        let projectNamesToCheck = projectNames;
        if (isRenaming) {
            projectNamesToCheck = projectNames.filter((name) => name !== selectedProject.name);
        }
        return projectNamesToCheck.includes(newProjectName);
    }, [projectNames, isRenaming, selectedProject, newProjectName]);

    useEffect(() => {
        async function createInitialProject() {
            try {
                await initializeNewProject(NEW_PROJECT_NAME);
                navigateToProjectURL(NEW_PROJECT_NAME);
            } catch (e) {
                addMessage({
                    message: PROJECT_INITIALIZING_ERROR,
                    type: NotificationType.ERROR,
                });
            }
        }

        if (!projectFilesData) {
            return;
        }

        const { files } = projectFilesData;

        if (files.length === 0) {
            createInitialProject();
        } else {
            const newProjectNames = [...files]
                .sort((file1, file2) => Date.parse(file2.created_at) - Date.parse(file1.created_at))
                .map((file) => fileNameExtensionRemover(file.name));
            setProjectNames(newProjectNames);
            if (!urlProjectName) {
                navigateToProjectURL(newProjectNames[0]);
            }
        }
    }, [projectFilesData]);

    useEffect(() => {
        async function loadProjectDataOnProjectNameChange() {
            try {
                const project = await loadProjectFromFile(urlProjectName as string);
                if (project.models.length > 0) {
                    dispatch(setSelectedModelIndex(0));
                    const firstModel = project.models[0];
                    firstModel.inputObjects?.forEach(({ id, version, name }) => {
                        loadGtmObject(id, version, name);
                    });
                }
            } catch (_e) {
                navigateToProjectURL(projectNames[0]);
            }
        }

        if (projectNames.length === 0) {
            return;
        }

        const newWorkspaceUuidAndProjectName = `${workspaceUuid}/${urlProjectName}`;
        if (previousWorkspaceUuidAndProjectName.current !== newWorkspaceUuidAndProjectName) {
            if (urlProjectName) {
                previousWorkspaceUuidAndProjectName.current = newWorkspaceUuidAndProjectName;
                loadProjectDataOnProjectNameChange();
            }

            // Same workspace selected again without selecting a project, navigate to first project
            const previousWorkspaceUuid =
                previousWorkspaceUuidAndProjectName.current?.split('/')[0];
            if (!urlProjectName && workspaceUuid === previousWorkspaceUuid) {
                navigateToProjectURL(projectNames[0]);
            }
        }
    }, [urlProjectName, workspaceUuid, projectNames]);

    const navigateToProjectURL = (navigateProjectName: string | undefined) => {
        const projectURL = navigateProjectName ? `project/${navigateProjectName}` : '';
        navigate(`/${orgUuid}/hub/${hubCode}/workspace/${workspaceUuid}/${projectURL}`);
    };

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const newSelection = event.target.value;

        if (newSelection === urlProjectName) {
            return;
        }

        navigateToProjectURL(newSelection);
    };

    const handleAddNewProject = () => {
        setNewProjectName(getNextSequentialName(NEW_PROJECT_NAME, projectNames));
        setIsAdding(true);
    };

    const handleProjectNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewProjectName(event.target.value);
    };

    const handleAddProjectConfirm = async () => {
        setIsSavingProject(true);
        try {
            await initializeNewProject(newProjectName);
            navigateToProjectURL(newProjectName);
        } catch (e) {
            addMessage({
                message: getProjectCreationErrorMessage(newProjectName),
                type: NotificationType.ERROR,
            });
        } finally {
            setNewProjectName('');
            setIsAdding(false);
            setIsSavingProject(false);
        }
    };

    const handleRenameProject = () => {
        setNewProjectName(selectedProject.name);
        setIsRenaming(true);
    };

    const handleRenameProjectConfirm = async () => {
        setIsSavingProject(true);
        try {
            await renameProject(selectedProject, newProjectName);
            navigateToProjectURL(newProjectName);
        } catch (e) {
            addMessage({
                message: PROJECT_RENAME_ERROR,
                type: NotificationType.ERROR,
            });
        } finally {
            setNewProjectName('');
            setIsRenaming(false);
            setIsSavingProject(false);
        }
    };

    const handleCancelAddOrRename = () => {
        setNewProjectName('');
        setIsAdding(false);
        setIsRenaming(false);
    };

    const handleAddOrRenameConfirm = () => {
        if (isAdding) {
            handleAddProjectConfirm();
        } else if (isRenaming) {
            handleRenameProjectConfirm();
        }
    };

    const handleDeleteProject = () => {
        if (projectNames.length === 1) {
            addMessage({
                message: AT_LEAST_ONE_PROJECT_REQUIRED_ERROR,
                type: NotificationType.ERROR,
            });
            return;
        }
        setProjectToDelete(selectedProject);
    };

    const handleDeleteProjectConfirm = async () => {
        if (!urlProjectName) {
            return;
        }

        await deleteProject(urlProjectName);
        const newSelectedProject = projectNames.filter((name) => name !== urlProjectName)[0];
        navigateToProjectURL(newSelectedProject);
        setProjectToDelete(null);
    };

    const handleDeleteProjectCancel = () => {
        setProjectToDelete(null);
    };

    const handleToggleProjectPanelMinimize = () => {
        dispatch(toggleProjectPanelMinimize());
    };

    return (
        <>
            <Box p={2}>
                {isAdding || isRenaming ? (
                    <Stack direction="row">
                        <TextField
                            variant="standard"
                            size="small"
                            value={newProjectName}
                            onChange={handleProjectNameOnChange}
                            error={isNewNameInvalid && !isSavingProject}
                            helperText={
                                isNewNameInvalid && !isSavingProject
                                    ? INVALID_NEW_PROJECT_NAME_MESSAGE
                                    : null
                            }
                        />
                        {isSavingProject ? (
                            <CircularProgress
                                sx={(theme) => ({ marginLeft: theme.spacing(3) })}
                                size={24}
                            />
                        ) : (
                            <Stack direction="row">
                                <IconButton
                                    size="small"
                                    disabled={
                                        isNewNameInvalid ||
                                        newProjectName.trim() === '' ||
                                        newProjectName === selectedProject.name
                                    }
                                    disableRipple
                                    onClick={handleAddOrRenameConfirm}
                                >
                                    <CheckIcon
                                        color={
                                            isNewNameInvalid ||
                                            newProjectName.trim() === '' ||
                                            newProjectName === selectedProject.name
                                                ? 'disabled'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    onClick={handleCancelAddOrRename}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Stack>
                        )}
                    </Stack>
                ) : (
                    <>
                        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
                            <FormControl>
                                <Select
                                    automation-id={applyTrace('select')}
                                    inputProps={{ sx: { paddingBottom: 0 } }}
                                    disableUnderline
                                    variant="standard"
                                    size="small"
                                    value={urlProjectName ?? ''}
                                    onChange={handleChange}
                                    MenuProps={{ slotProps: { paper: { sx: { maxHeight: 400 } } } }}
                                >
                                    {projectNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack direction="row">
                                <IconButton
                                    sx={{ padding: 0 }}
                                    size="small"
                                    onClick={handleOpenMenu}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    automation-id={applyTrace('toggle-project-panel')}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    onClick={handleToggleProjectPanelMinimize}
                                >
                                    {shouldMinimizeProjectPanel ? (
                                        <SidebarRight fontSize="small" color="primary" />
                                    ) : (
                                        <SidebarLeft fontSize="small" color="primary" />
                                    )}
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Typography sx={{ display: 'block' }} color="secondary" variant="caption">
                            {workspaceName}
                        </Typography>
                        <PanelItemMenu
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            onAdd={handleAddNewProject}
                            onDelete={handleDeleteProject}
                            onRename={handleRenameProject}
                        />
                    </>
                )}
            </Box>
            <DeleteDialog
                shouldOpen={Boolean(projectToDelete)}
                message={getDeleteProjectMessage(selectedProject.name)}
                handleCancel={handleDeleteProjectCancel}
                handleDelete={handleDeleteProjectConfirm}
            />
        </>
    );
}
